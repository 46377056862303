<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>数据展示</el-breadcrumb-item>
      <el-breadcrumb-item>项目总情况</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片区 -->
    <el-card
      v-if="
        roleName != '资料员' &&
        roleName != '资料主管' &&
        roleName != '试验员' &&
        roleName != '试验主管'
      "
    >
      <div class="process-all">
        <el-progress
          type="dashboard"
          :percentage="percentage1"
          v-if="
            roleName != '资料员' &&
            roleName != '资料主管' &&
            roleName != '试验员' &&
            roleName != '试验主管'
          "
        >
          <template #default="{ percentage }">
            <span class="percentage-value">{{ percentage }}%</span>
            <span class="percentage-label">项目总进度</span>
          </template>
        </el-progress>

        <el-progress
          type="dashboard"
          :percentage="percentage2"
          style="margin-left: 50px"
          v-if="
            roleName != '资料员' &&
            roleName != '资料主管' &&
            roleName != '试验员' &&
            roleName != '试验主管'
          "
        >
          <template #default="{ percentage }">
            <span class="percentage-value">{{ percentage }}%</span>
            <span class="percentage-label">除去甩项总进度</span>
          </template>
        </el-progress>

        <div class="word">
          <span
            v-if="
              roleName != '资料员' &&
              roleName != '资料主管' &&
              roleName != '试验员' &&
              roleName != '试验主管' &&
              roleName != '施工员' &&
              roleName != '施工主管'
            "
            >项目总投资：{{ price1 }} 万元</span
          >
          <br />
          <span
            v-if="
              roleName != '资料员' &&
              roleName != '资料主管' &&
              roleName != '试验员' &&
              roleName != '试验主管' &&
              roleName != '施工员' &&
              roleName != '施工主管'
            "
            >甩项以后总金额：{{ price2 }}万元</span
          >
          <br />
          <span
            v-if="
              roleName != '资料员' &&
              roleName != '资料主管' &&
              roleName != '试验员' &&
              roleName != '试验主管'
            "
            >截止当天累计生产值：{{ price3 }}万元</span
          >
          <br />
        </div>
      </div>
    </el-card>

    <!-- 试验员完成情况 -->
    <el-card
      v-if="
        this.roleName != '资料员' &&
        this.roleName != '资料主管' &&
        this.roleName != '施工员' &&
        this.roleName != '施工主管'
      "
      style="margin-top: 15px"
    >
      <div>
        <el-row :gutter="20" style="margin-bottom: 15px">
          <el-col :span="6"
            ><span class="title">试验员-试验检测完成情况表</span></el-col
          >
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input
              v-model="docQuery.project"
              class="w-50 m-2"
              placeholder="工程名称"
              :suffix-icon="Search"
            />
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="docQuery.item"
              class="w-50 m-2"
              placeholder="试验检测项目"
              :suffix-icon="Search"
            />
          </el-col>
          <el-col :span="6">
            <el-input
              v-model="docQuery.material"
              class="w-50 m-2"
              placeholder="材料实体名称"
              :suffix-icon="Search"
            />
          </el-col>
          <el-col :span="6">
            <el-select
              v-model="select"
              class="m-2"
              placeholder="Select"
              clearable
              @change="docSort"
              @clear="clearSort"
            >
              <el-option
                v-for="(item, index) in sort"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
            <el-button icon="el-icon-search" @click="confirm()"></el-button>
          </el-col>
        </el-row>
        <el-table :data="completion" border stripe style="margin-bottom: 15px">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column
            label="工程名称"
            min-width="140"
            prop="projectName"
          ></el-table-column>
          <el-table-column
            label="材料及实体名称"
            min-width="120"
            prop="materialEntityName"
          ></el-table-column>
          <el-table-column
            label="检测项目"
            min-width="140"
            prop="testItem"
          ></el-table-column>
          <el-table-column
            label="检测频率"
            min-width="160"
            prop="testFrequency"
          ></el-table-column>
          <el-table-column
            label="说明"
            min-width="140"
            prop="explain"
          ></el-table-column>
          <el-table-column
            label="工程量单位"
            prop="quantityUnit"
          ></el-table-column>
          <el-table-column
            label="清单工程量"
            prop="listQuantity"
          ></el-table-column>
          <el-table-column
            label="现场进度工程量"
            prop="progressQuantity"
          ></el-table-column>
          <el-table-column label="检测单位" prop="testUnit"> </el-table-column>
          <el-table-column label="检测系数" prop="testRatio">
          </el-table-column>
          <el-table-column
            label="清单应检数量"
            prop="listRequiredQuantity"
          >
          </el-table-column>
          <el-table-column label="进度检测数量" prop="progressRequiredQuantity">
          </el-table-column>
          <el-table-column label="实际应检数量" prop="actualRequiredQuantity">
          </el-table-column>
          <el-table-column label="已取检测报告数量" prop="obtainedQuantity">
          </el-table-column>
          <el-table-column label="未取检测报告数量" prop="notObtainedQuantity">
          </el-table-column>
          <el-table-column label="检测合格数量" prop="passQuantity">
          </el-table-column>
          <el-table-column label="检测不合格数量" prop="notPassQuantity">
          </el-table-column>
          <el-table-column label="工程名" prop="companyItemName">
          </el-table-column>
          <el-table-column label="公司" prop="companyName"> </el-table-column>
          <el-table-column label="编号" prop="id"> </el-table-column>
          <el-table-column label="修改时间" prop="updateTime">
          </el-table-column>
          <el-table-column label="上报员编号" prop="experimentId">
          </el-table-column>
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination
            :current-page="docQuery.pagenum"
            :page-size="docQuery.pagesize"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total1"
            @size-change="docSizeChange"
            @current-change="docCurChange"
          />
        </el-config-provider>
        <div class="sum">
          <div>实际检测数量 :{{ this.sum.actual }}</div>
          <div>已取检测报告数量 :{{ this.sum.get }}</div>
          <div>未取检测报告数量 :{{ this.sum.noget }}</div>
          <div>检测合格数量 :{{ this.sum.pass }}</div>
          <div>检测不合格数量 :{{ this.sum.nopass }}</div>
        </div>
      </div>
    </el-card>
    <!-- 资料员竣工情况 -->
    <el-card
      v-if="
        this.roleName != '试验员' &&
        this.roleName != '试验主管' &&
        this.roleName != '施工员' &&
        this.roleName != '施工主管'
      "
      style="margin-top: 15px"
    >
      <div>
        <el-row :gutter="20" style="margin-bottom: 15px">
          <el-col :span="6"
            ><span class="title">资料员-竣工资料情况表</span></el-col
          >
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input v-model="dataQuery.sign" placeholder="未签字的单位" />
          </el-col>
          <el-col :span="10">
            <el-select
              v-model="select"
              placeholder="Select"
              clearable
              @change="dataSortDoc"
              @clear="clearData"
            >
              <el-option
                v-for="(item, index) in dataSort"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
            <el-button icon="el-icon-search" @click="dataconfirm()"></el-button>
          </el-col>
        </el-row>
        <el-table
          :data="dataCompletion"
          border
          stripe
          style="margin-bottom: 15px"
        >
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="id" prop="id"></el-table-column>
          <el-table-column
            label="册数"
            min-width="120"
            prop="volume"
          ></el-table-column>
          <el-table-column
            label="文件材料名称"
            min-width="140"
            prop="documentContentName"
          ></el-table-column>
          <el-table-column
            label="资料说明"
            min-width="160"
            prop="documentDescription"
          ></el-table-column>
          <el-table-column
            label="资料完成情况说明"
            min-width="140"
            prop="documentCompleteDescription"
          ></el-table-column>
          <el-table-column label="总数" prop="totalNum"></el-table-column>
          <el-table-column
            label="编制数"
            prop="organizationNum"
          ></el-table-column>
          <el-table-column
            label="监理签认数"
            prop="supervisorNum"
          ></el-table-column>
          <el-table-column label="设计签认数" prop="designNum">
          </el-table-column>
          <el-table-column label="建设单位签认数" prop="constructionNum">
          </el-table-column>
          <el-table-column label="存档原件数" prop="archiveOriginalNum">
          </el-table-column>
          <el-table-column label="存档复印件数" prop="archiveCopyNum">
          </el-table-column>
          <el-table-column label="更新时间" prop="updateTime">
          </el-table-column>
          <el-table-column label="资料表id" prop="documentId">
          </el-table-column>
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination
            :current-page="dataQuery.pagenum"
            :page-size="dataQuery.pagesize"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total2"
            @size-change="dataSizeChange"
            @current-change="dataCurChange"
          />
        </el-config-provider>
        <div class="sum">
          <div>全部资料共 :{{ this.dataSum.total }} 份</div>
          <div>已编制完成 :{{ this.dataSum.organization }} 份</div>
          <div>监理已签认 :{{ this.dataSum.supervisor}} 份</div>
          <div>设计已签认 :{{ this.dataSum.design}} 份</div>
          <div>建设单位已签认 :{{ this.dataSum.construction }} 份</div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {
  props: ["itemName"],
  watch: {
    itemName: function (newV, oldV) {
      this.experimentShow();
      this.documentShow();
    },
  },
  data() {
    return {
      select: "",
      roleName: "",
      // 总进度
      cur: {},
      //试验员完成情况
      docQuery: {
        pagenum: 1,
        pagesize: 5,
        curItemId: "",
        project: "",
        material: "",
        item: "",
        get: 0,
        noget: 0,
        pass: 0,
        nopass: 0,
      },
      dataQuery: {
        pagenum: 1,
        pagesize: 5,
        curItemId: "",
        organ: 0,
        supervisor: 0,
        design: 0,
        constr: 0,
        sign: 1,
        all: 0,
      },
      total1: "",
      total2: "",
      // 分类
      sort: [
        "实际检测数量",
        "已取检测报告数量",
        "未取检测报告数量",
        "检测合格数量",
        "检测不合格数量",
      ],
      dataSort: ["全部资料","已编制完成", "监理已签认", "设计已签认", "建设单位已签认"],
      completion: [],
      dataCompletion: [],
      // 试验完成情况总结
      sum: {},
      // 竣工总结
      dataSum: {},

      percentage1: "",
      percentage2: "",
      price1: "",
      price2: "",
      price3: "",
    };
  },
  setup() {
    return {
      locale: zhCn,
    };
  },
  mounted() {
    setTimeout(() => {
      this.jurisdiction();
    }, 100),
      setTimeout(() => {
        if (
          this.roleName != "资料员" &&
          this.roleName != "资料主管" &&
          this.roleName != "试验员" &&
          this.roleName != "试验主管"
        ) {
          this.getTotalProgress();
          this.getJiltTotalProgress();
          this.getCompletedValue();
        }

        if (
          this.roleName != "资料员" &&
          this.roleName != "资料主管" &&
          this.roleName != "试验员" &&
          this.roleName != "试验主管" &&
          this.roleName != "施工员" &&
          this.roleName != "施工主管"
        )
         {
          this.getContractAmount();
          this.getJiltContractAmount();
        }
        if (
          this.roleName != "资料员" &&
          this.roleName != "资料主管" &&
          this.roleName != "施工员" &&
          this.roleName != "施工主管"
        ) {
          this.experimentShow();
        }
        if (
          this.roleName != "试验员" &&
          this.roleName != "试验主管" &&
          this.roleName != "施工员" &&
          this.roleName != "施工主管"
        ) {
          this.documentShow();
        }
      }, 200);
  },
  methods: {
    // 转换时间戳为日期格式方法
    add(m) {
      return m < 10 ? "0" + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        y +
        "-" +
        this.add(m) +
        "-" +
        this.add(d) +
        " " +
        this.add(h) +
        ":" +
        this.add(mm)
      );
    },

    //权限
    async jurisdiction() {
      this.roleName = window.sessionStorage.getItem("roleName");
    },
    // 项目总进度
    async getTotalProgress() {
      this.cur.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: res } = await this.$http.get(
        "/reportForm/getTotalProgress",
        { params: this.cur }
      );
      console.log(res);
      if (res.code != 200) {
        return this.$message.error(res.msg);
      } else {
        console.log(res.data);
        this.percentage1 = res.data;
      }
    },
    // 甩项总进度
    async getJiltTotalProgress() {
      this.cur.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: resp } = await this.$http.get(
        "/reportForm/getJiltTotalProgress",
        { params: this.cur }
      );
      if (resp.code != 200) {
        return this.$message.error(resp.msg);
      } else {
        this.percentage2 = resp.data;
      }
    },
    // 项目总投资
    async getContractAmount() {
      this.cur.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: resp } = await this.$http.get(
        "/reportForm/getContractAmount",
        { params: this.cur }
      );
      if (resp.code != 200) {
        return this.$message.error(resp.msg);
      } else {
        this.price1 = resp.data;
      }
    },
    // 甩项总金额
    async getJiltContractAmount() {
      this.cur.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: resp } = await this.$http.get(
        "/reportForm/getJiltContractAmount",
        { params: this.cur }
      );
      if (resp.code != 200) {
        return this.$message.error(resp.msg);
      } else {
        this.price2 = resp.data;
      }
    },
    //截止当天累计生产值
    async getCompletedValue() {
      this.cur.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: resp } = await this.$http.get(
        "/reportForm/getCompletedValue",
        { params: this.cur }
      );
      if (resp.code != 200) {
        return this.$message.error(resp.msg);
      } else {
        this.price3 = resp.data;
      }
    },
    //试验完成情况
    async experimentShow() {
      this.docQuery.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: res } = await this.$http.get(
        "/experimenter/experimentShow",
        { params: this.docQuery }
      );
      if (res.code != 200) {
        return this.$message.error(res.msg);
      } else {
        var temp = res.data.content.list;
        // 将时间戳转换为日期格式
        for (var i = 0; i < temp.length; i++) {
          temp[i].updateTime = this.format(temp[i].updateTime);
        }
        this.total1 = res.data.content.total;
        this.completion = temp;
        this.sum = res.data.sum;
      }
    },
    docSort(item) {
      if (item === "实际检测数量") {
        this.docQuery.get = 1;
      } else if (item === "已取检测报告数量") {
        this.docQuery.noget = 1;
      } else if (item === "未取检测报告数量") {
        this.docQuery.pass = 1;
      } else if (item === "检测合格数量") {
        this.docQuery.nopass = 1;
      }
    },
    clearSort(item) {
      this.docQuery.get = 0;
      this.docQuery.noget = 0;
      this.docQuery.pass = 0;
      this.docQuery.nopass = 0;
    },
    confirm() {
      this.experimentShow();
    },
    //试验完成情况表格分页
    docSizeChange(newSize) {
      this.docQuery.pagesize = newSize;
      this.experimentShow();
    },
    docCurChange(newPage) {
      this.docQuery.pagenum = newPage;
      this.experimentShow();
    },
    // 资料竣工表
    async documentShow() {
      this.dataQuery.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: res } = await this.$http.get("/documenter/documentShow", {
        params: this.dataQuery,
      });
      console.log(res,11);
      if (res.code != 200) {
        return this.$message.error(res.msg);
      } else {
        var temp = res.data.content.list;
        // 将时间戳转换为日期格式
        for (var i = 0; i < temp.length; i++) {
          temp[i].updateTime = this.format(temp[i].updateTime);
        }
        this.total2 = res.data.content.total;
        this.dataCompletion = temp;
        this.dataSum = res.data.sum;
      }
    },
    //资料表格分页
    dataSizeChange(newSize) {
      this.dataQuery.pagesize = newSize;
      this.documentShow();
    },
    dataCurChange(newPage) {
      this.dataQuery.pagenum = newPage;
      this.documentShow();
    },
    dataSortDoc(item) {
      if (item === "已编制完成") {
        this.dataQuery.organ = 1;
      } else if (item === "监理已签认") {
        this.dataQuery.supervisor = 1;
      } else if (item === "设计已签认") {
        this.dataQuery.design = 1;
      } else if (item === "建设单位已签认") {
        this.dataQuery.constr = 1;
      } else if (item === "全部资料"){
        this.dataQuery.all = 1;
      }
    },
    clearData(item) {
      this.dataQuery.organ = 0;
      this.dataQuery.design = 0;
      this.dataQuery.supervisor = 0;
      this.dataQuery.constr = 0;
      this.dataQuery.all = 0;
    },
    dataconfirm() {
      this.documentShow();
    },
  },
};
</script>

<style lang="less" scoped>
.percentage-value {
  display: block;
  margin-top: 10px;
  font-size: 28px;
}

.percentage-label {
  display: block;
  margin-top: 10px;
  font-size: 12px;
}
.sum {
  line-height: 30px;
  font-size: 20px;
}
</style>
